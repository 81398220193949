import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <p>Stay curious and humble</p>

      <StaticImage
        alt="A nice home picture"
        src="https://images.unsplash.com/photo-1536319229365-83318cdc7b83?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80"
      />
    </Layout>
  )
}

export default IndexPage
